/* eslint-disable no-invalid-this */
import { sendLog, sendUserInfo, sendExternalAssetModificationLog } from "api-operations/logger/logger";
import { getUserErrorMessage } from "errors/error-user";
export const HTTPLogger = class HTTPLogger {
    static sendLog(logLevel, message, params) {
        const path = window.location.pathname;
        if (process.env.ENABLE_LOGGER) {
            sendLog(logLevel, message, path, params !== null && params !== void 0 ? params : {});
        }
        else {
            console.log(message);
            if (params)
                console.error(JSON.stringify(params));
        }
    }
    static sendClientLog(params) {
        if (process.env.ENABLE_LOGGER) {
            sendUserInfo(params);
        }
        else {
            console.log(JSON.stringify(params));
        }
    }
    static send(level, message, params) {
        this.sendLog(level, message, params);
    }
    static debug(message, params) {
        this.send("DEBUG", message, params);
    }
    static info(message, params) {
        this.send("INFO", message, params);
    }
    static warn(message, params) {
        this.send("WARN", message, params);
    }
    static error(message, error, params) {
        const stack = { stack: new Error().stack };
        if (error) {
            const errorMsg = getUserErrorMessage(error);
            this.send("ERROR", message, { ...params, error: { ...errorMsg, ...stack } });
        }
        else {
            this.send("ERROR", message, params);
        }
    }
    static browserInfo() {
        var _a, _b, _c;
        const newNavigator = navigator;
        const settings = {
            timezone: (new Date()).getTimezoneOffset() / 60,
            // screen
            sizeScreenW: screen.width,
            sizeScreenH: screen.height,
            sizeInW: innerWidth,
            sizeInH: innerHeight,
            // navigator
            connectionType: (_b = (_a = newNavigator.connection) === null || _a === void 0 ? void 0 : _a.effectiveType) !== null && _b !== void 0 ? _b : undefined,
            userAgent: newNavigator.userAgent,
            vendor: newNavigator.vendor,
            maxTouchPoints: newNavigator.maxTouchPoints,
            deviceMemory: (_c = newNavigator.deviceMemory) !== null && _c !== void 0 ? _c : 0,
            language: newNavigator.language
        };
        this.sendClientLog(settings);
    }
    static externalAssetModification(externalAssetModificationLog) {
        if (process.env.ENABLE_LOGGER) {
            sendExternalAssetModificationLog(externalAssetModificationLog);
        }
        else {
            console.log(JSON.stringify(externalAssetModificationLog));
        }
    }
};
